import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

interface DialogProps {
    isOpen: boolean;
    onClose: () => void;
    maxWidth?: string;
    escKeyClose?: boolean;
    className?: string;
}

const Dialog = (props: React.PropsWithChildren<DialogProps>) => {
    const ref = React.useRef();

    useEffect(() => {
        if (props.isOpen && !ref.current?.open) {
            ref.current.showModal();
            ref.current.addEventListener('cancel', (e) => {
                e.preventDefault();

                if (props.escKeyClose) {
                    //props.onClose();
                }
            });
        } else if (ref.current?.open && !props.isOpen) {
            const hideDialog = () => {
                ref.current.classList.remove('hide');
                ref.current.close();
                ref.current.removeEventListener('webkitAnimationEnd', hideDialog, false);
            }

            ref.current.classList.add('hide');
            // ref.current.removeEventListener('webkitAnimationEnd', hideDialog, false);
            ref.current.addEventListener('webkitAnimationEnd', hideDialog);
        }
    }, [props.isOpen, ref.current]);
    
    return <dialog
        ref={ref}
        className={classNames(
            ' dialog bg-white shadow-xl text-slate-600 border-0 w-full outline-none rounded-lg p-10 backdrop:bg-slate-900/50 backdrop:backdrop-filter backdrop:backdrop-blur-[2px]',
            props.className
        )}
        style={{
            maxWidth: props.maxWidth
        }}
        >

        <button type='button' className='fixed top-4 right-4 text-white outline-none' onClick={props.onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-10 h-10">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
        </button>

        { props.children }

    </dialog>;
};

Dialog.defaultProps = {
    maxWidth: '1024px',
    escKeyClose: true
};

export default Dialog;