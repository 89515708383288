import './scss/main.scss'
import '@fontsource/fira-sans/300.css';
import '@fontsource/fira-sans/400.css';
import '@fontsource/fira-sans/500.css';
import '@fontsource/fira-sans/700.css';
import '@fontsource/fira-sans/900.css';
import '@fontsource-variable/inter';

import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./hooks/useAuth";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

if (import.meta.env.MODE == 'production') {
    Bugsnag.start({
        apiKey: 'a699cf489b193465f7ebef1c5e49d974',
        plugins: [new BugsnagPluginReact()],
    })
}

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById('app')).render(
    <BrowserRouter>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <AuthProvider>
                <App />
            </AuthProvider>
        </QueryClientProvider>
    </BrowserRouter>
)