import React, { useState, useEffect } from 'react';
import Modal from './Modal';
import SemVer from 'semver';
import log from '/changelog.json';
import Dialog from './Dialog';

const ChangelogModal = () => {
    const [showModal, setShowModal] = useState(false);
    const [changelog, setChangelog] = useState({});

    useEffect(() => {
        const getChangelog = async () => {
            let localVersion = await localStorage.getItem('lastVersion');
            const localLogs = {};

            if (localVersion === null) {
                localVersion = Object.keys(log)[1];
            }

            for (const version in log) {
                if (localVersion === null || SemVer.lt(localVersion, version)) {
                    localLogs[version] = log[version];
                }
            }

            setChangelog(localLogs);

            if (Object.keys(localLogs).length > 0) setShowModal(true);
        }

        getChangelog();
    }, []);
    
    const closeModal = () => {
        localStorage.setItem('lastVersion', Object.keys(changelog)[0]);
        setShowModal(false);
    }
    
    return (
        <Dialog isOpen={showModal} onClose={closeModal} closeOnBackdropClick={false} maxWidth={700} className='p-6 xl:p-10 lg:text-lg lg:leading-tight text-slate-600'>
            <h1 className="text-3xl font-bold mb-4 text-primary">Update - Version { Object.keys(changelog)[0] }</h1>
            <p className='mb-6'>Booxboo wurde auf die neueste Version aktualisiert. Nachfolgend sehen Sie, was es Neues gibt:</p>
            
            { Object.keys(changelog).map((version, index) => (
                <div key={index}>
                    <h2 className="text-xl font-bold mb-3 text-primary">Version { version }</h2>
                    <ul className='mb-6 text-base leading-tight space-y-2 ml-7 list-outside' style={{ listStyleType: 'square'}}>
                        { changelog[version].map((entry, index) => (
                            <li key={index}>{ entry }</li>
                        )) }
                    </ul>
                </div>
            )) }

            <div className="flex justify-center">
                <button className="btn btn-primary text-base px-6" onClick={closeModal}>Schließen</button>
            </div>
        </Dialog>
    );
}

export default ChangelogModal;