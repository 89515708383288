import axios from 'axios';
import { useAuth } from './useAuth';

const apiBase = import.meta.env.VITE_API_BASE;

export default () => {
    axios.defaults.baseURL = apiBase;

    const { logout } = useAuth();

    axios.interceptors.request.use((config) => {
        const token = localStorage.getItem('token');
        config.headers.Authorization = `Bearer ${token}`;

        // filter empty request params
        if (config.params) {
            Object.keys(config.params).forEach((key) => {
                if (config.params[key] === null || config.params[key] === undefined || config.params[key] === '') {
                    delete config.params[key];
                }
            });
        }

        return config;
    });

    axios.interceptors.response.use(response => response, (error) => {
        if (error.response?.status === 401) {
            logout(false);
        }

        return Promise.reject(error);
    });

    return axios;
}