import { useCombobox } from "downshift";
import React, { useRef, useState } from "react";
import useElastic from "@/hooks/useElastic";
import classNames from "classnames";
import PropTypes from 'prop-types';

let timer = null;

const Autocomplete = (props) => {
    const [items, setItems] = useState([]);
    const { search } = useElastic();
    const [isSearching, setIsSearching] = useState(false);
    const inputRef = useRef();

    const {
        isOpen,
        openMenu,
        inputValue,
        getToggleButtonProps,
        getLabelProps,
        getMenuProps,
        getInputProps,
        getComboboxProps,
        highlightedIndex,
        getItemProps,
        selectedItem,
        reset,
    } = useCombobox({
        items,
        defaultHighlightedIndex: 0,
        onInputValueChange: async ({ inputValue }) => {
            if (timer) {
                clearTimeout(timer);
            }

            if (inputValue.trim() === "") {
                setItems([]);   
                return;
            }

            setIsSearching(true);

            timer = setTimeout(async () => {
                
                let results = await props.onSearch(inputValue);

                // if results is not an array, we assume it's an error
                if (!Array.isArray(results)) {
                    console.warn('Autocomplete: onSearch() expects an array of items, but got', results);

                    results = [];
                }

                setItems(results);
                setIsSearching(false);
            }, !isNaN(props.debounceTime) ? props.debounceTime : 300);
        },
        onSelectedItemChange: ({ selectedItem }) => {
            props.onSelect(selectedItem);
            // reset();
            inputRef.current.blur();
        },
        itemToString: (item) => "",
    });

    return (
        <div className={'relative ' + props.containerClassName } {...getComboboxProps({ ref: inputRef })}>
            <input
                ref={inputRef}
                spellCheck={false}
                className={classNames(
                    'relative z-0 w-full',
                    props.className
            )}
                type="text" 
                placeholder={props.placeholder}
                {...getInputProps()}
                onFocus={() => {
                    if (items.length > 0 && inputValue.trim() !== "") {
                        openMenu();
                    }
                }}
            />
            { isSearching && <div className="absolute right-4 top-1/2 -translate-y-1/2">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" className="w-5 h-5 fill-slate-700 animate-spin"><path fill="none" d="M0 0h24v24H0z"/><path d="M18.364 5.636L16.95 7.05A7 7 0 1 0 19 12h2a9 9 0 1 1-2.636-6.364z"/></svg>
            </div> }
            <div {...getMenuProps()} className={classNames(
                'absolute z-10 w-full bg-white rounded-md shadow-lg transition-all duration-300 top-full mt-1 p-4 flex items-center justify-center text-lg',
                (isOpen && items.length === 0 && inputValue.trim() !== "" && !isSearching)
                    ? 'translate-y-0 visible opacity-100'
                    : '-translate-y-3 invisible opacity-0',
                // { '-translate-y-3 invisible opacity-0': (!isOpen || items.length === 0 || inputValue.trim() === "" || isSearching) },
                // { 'translate-y-0 visible opacity-100': isOpen && items.length == 0 && inputValue.trim() !== "" && !isSearching },
                props.menuClassName
            )}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.8} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z" />
                </svg>
                <span className="ml-2">Keine Ergebnisse</span>
            </div>
            <ul {...getMenuProps()} className={classNames(
                'absolute z-10 w-full bg-white rounded-md shadow-lg transition-all duration-300 top-full mt-1 divide-y overflow-auto max-h-80 divide-slate-200 scrollbar-thin scrollbar-thumb-slate-400 scrollbar-track-slate-100 scrollbar-track-rounded-full scrollback-thumb-rounded-full',
                { '-translate-y-3 invisible opacity-0': (!isOpen || items.length === 0) },
                { 'translate-y-0 visible opacity-100': isOpen && items.length > 0 },
                props.menuClassName
            )}>
                {isOpen && items.map((item, index) => (
                    <li {...getItemProps({ item, index })} key={index}>
                        { props.renderItem ? props.renderItem(item, highlightedIndex === index) : <div className={classNames(
                            'px-4 py-2',
                            { 'bg-slate-100': highlightedIndex === index }
                        )}>
                            {item._source.title}
                        </div> }
                    </li>
                ))}
            </ul>
        </div>
    )
}

Autocomplete.propTypes = {
    onSearch: PropTypes.func.isRequired,
    menuClassName: PropTypes.string,
}

Autocomplete.defaultProps = {
    placeholder: 'Suchbegriffe eingeben...',
    debounceTime: 150,
    onSearch: () => {
        console.warn('Autocomplete: onSearch() is not defined');
        return [];
    },
    onSelect: () => {},
    renderItem: null,
    menuClassName: '',
}

export default Autocomplete;